import { Injectable, NgZone } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class WindowEventsService {
    private windowSize: { innerHeight: number, innerWidth: number };
    private resizeSubject = new Subject<Event>();
    public resize$ = this.resizeSubject.asObservable();
    public windowRef = window;

    constructor(private ngZone: NgZone) {
        this.listenToResize();
    }

    private listenToResize() {
        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'resize')
                .pipe(debounceTime(150))
                .subscribe(event => {
                    if (!this.windowSize) this.windowSize = { innerHeight: window.innerHeight, innerWidth: window.innerWidth };
                    else if ((this.windowSize.innerHeight != window.innerHeight || this.windowSize.innerWidth != window.innerWidth)) {
                        this.windowSize = { innerHeight: window.innerHeight, innerWidth: window.innerWidth };
                        this.resizeSubject.next(event);
                    }
                });
        });
    }
}
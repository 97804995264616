import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as go from 'gojs';
import { Config } from './config';
import * as wjcCore from '@grapecity/wijmo';
import { WindowEventsService } from 'core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dfm-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService,
    private windowEventsService: WindowEventsService
  ) {
    this.setWijmoLicenseKey();
    this.setGoJsLicenseKey();
    this.setDefaultLanguage();
    this.listenToResizeEvent();
  }

  ngOnInit(): void { }

  setWijmoLicenseKey() {
    wjcCore.setLicenseKey(Config.wijmoLicenseKey);
  }

  setGoJsLicenseKey(): void {
    (go as any).licenseKey = Config.goJsLicenseKey;
  }

  setDefaultLanguage(): void {
    const languageCode = localStorage.getItem('languageCode');
    this.translateService.setDefaultLang(languageCode ? languageCode : Config.defaultLanguageCode);
  }

  listenToResizeEvent() {
    this.windowEventsService.resize$.pipe(takeUntil(this.destroy$)).subscribe(_ => this.windowEventsService.windowRef.location.reload());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Injectable } from "@angular/core";
import { IConfig } from "core/config";
import { IConfigMap } from "./configMap";
import { devConfig } from "./devConfig.map";
import { getWindow } from "./getWindow";
import { kubernetesConfig } from "./kubernetesConfig.map";
import { uatConfig } from "./uatConfig.map";
import { UserAutoFocusoutTime } from "core/constants";

export function staticConfig<T extends IConfig>(constructor: T) {
  return constructor;
}

@Injectable()
@staticConfig
export class Config {
  // private static get environmentConfig(): IConfigMap {
  //   const devUrl = "dev.modeler2.decisionsfirst.com";
  //   const kubernetesUrl = "localhost:8070";

  //   switch (getWindow().location.hostname) {
  //     case devUrl:
  //       return devConfig;
  //     case kubernetesUrl:
  //       return kubernetesConfig;
  //       default: return devConfig;
  //   }
  // }
  private static currentConfig: IConfigMap | null = null;
  private static get environmentConfig(): IConfigMap {
    if (!this.currentConfig) {
      // Set default based on hostname
      this.setEnvironmentConfig(getWindow().location.hostname);
    }
    return this.currentConfig!;
  }
  public static setEnvironmentConfig(envKey: string): void {
    if (envKey.includes("localhost:8070")) {
      this.currentConfig = kubernetesConfig; // Use Kubernetes configuration for localhost
      return;
    }
    switch (envKey) {
      case 'dev':
        this.currentConfig = devConfig;
        break;
      case 'kubernetes':
        this.currentConfig = kubernetesConfig;
        break;
        case 'uat':
        this.currentConfig = uatConfig;
        break;
        
       default:
         this.currentConfig = kubernetesConfig; // Default fallback
    }
  }
  public static get proxyUri(): string {
    return this.environmentConfig.proxyUri;
  }

  public static get rootUri(): string {
    return this.environmentConfig.rootUri;
  }

  public static get authBaseUrl(): string {
    return this.environmentConfig.authBaseUrl;
  }

  public static get environment(): string {
    return this.environmentConfig.environment;
  }

  public static get identityManagement(): string {
    return this.environmentConfig.identityManagement;
  }

  public static get defaultLanguageCode(): string {
    return "en";
  }

  public static get pageSize(): number {
    return 10;
  }

  public static get debounceTime(): number {
    return 2000;
  }

  public static get searchDebounceTime(): number {
    return 250;
  }

  public static get wijmoLicenseKey(): string {
    return this.environmentConfig.wijmoLicenseKey;
  }

  public static get goJsLicenseKey(): string {
    return this.environmentConfig.goJsLicenseKey;
  }

  public static get autocompleteListPageSize(): number {
    return 3;
  }

  public static get environmentName(): string {
    return this.environmentConfig.name;
  }

  public static get timeControlDebounceTime(): number {
    return 200;
  }

  public static get userAutoFocusoutTime(): number {
    return UserAutoFocusoutTime;
  }
  public static get simulationrootUri(): string {
    return this.environmentConfig.simulationUri;
  }
}
